import React from "react";
import { CallOutlined, MoveToInboxOutlined } from "@mui/icons-material";

export default function CommLogHeader({ icon, title }) {
  const getIcon = (iconName) => {
    switch (iconName) {
      case "phone":
        return <CallOutlined />;
      case "sms":
        return <MoveToInboxOutlined />;
      default:
        return <CallOutlined />;
    }
  };
  return (
    <div className="px-3 pt-4 flex justify-between flex-row-reverse border-black border-1 border-b mx-3">
      <div className="w-[50%] mx-auto my-2 text-right"></div>

      <div className="w-[50%] self-end rounded-t-md">
        <h2 className="w-full text-center py-1 text-lg flex items-center flex-row">
          <div className="block mr-1 mb-1">{getIcon(icon)}</div>
          <span className="block">{title}</span>
        </h2>
      </div>
    </div>
  );
}
