import { LineChart } from "@mui/x-charts";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import { CircularProgress, Tooltip } from "@mui/material";
import { Help } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function InjuryGraph({ caseObj, trigger }) {
  const { userRoles, accessToken } = useContext(DataContext);
  const [graphData, setGraphData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      !userRoles.permissions.includes("casemanager.view_casereview") ||
      !caseObj
    ) {
      return;
    } else {
      setLoading(true);
      axios
        .get(`/api/case/${caseObj.pk}/injury_graph/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const data = response.data;
          setLoading(false);
          setGraphData(data);
        })
        .catch((res) => {
          setLoading(false);
          return res;
        });
    }
  }, [userRoles.permissions, accessToken, setGraphData, caseObj, trigger]);

  const createGraphData = (body, general) => {
    let returnData = [
      {
        data: general,
        label: "General",
        type: "line",
      },
    ];

    for (const item in body) {
      let label = Object.keys(body[item]);
      returnData.push({
        data: body[item][label[0]],
        label: label[0],
        type: "line",
      });
    }

    return returnData;
  };

  const customColors = [
    //netprocolors
    "#9333ea",
    "#0284c7",
    "#16a34a",
    "#fde047",
    "#d97706",
    "#dc2626",
    //misc+random
    "#881337",
    "#48592f",
    "#134e6f",
    "#bdbfbf",
    "#ea580c",
    "#334155",
    "#fdba74",
    "#fda4af",
    "#a5f3fc",
    "#ff6150",
    "#1ac0c6",
    "#dee0e6",
    "#222626",
    "#d8b4fe",
    "#ca8a04",
    "#065f46",
    "#0d9488",
    "#7e8c61",
    "#ffa822",
    "#9ea68f",
  ];

  return !graphData ? (
    <Tooltip title="Add injury data to populate graph">
      <Help className="text-[2rem] text-sky-600 mx-auto text-center" />
    </Tooltip>
  ) : graphData && graphData.dates.length === 0 ? (
    <Tooltip title="Add injury data to populate graph">
      <Help className="text-[2rem] text-sky-600 mx-auto text-center" />
    </Tooltip>
  ) : !createGraphData(graphData.injuryData, graphData.general).length ||
    loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <div className="h-[60vh] w-full pb-6">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <h2 className="text-[1.2rem] font-bold">Pain Levels vs Time</h2>
        <LineChart
          colors={customColors}
          margin={{ bottom: 75 }}
          slotProps={{
            legend: {
              position: { vertical: "bottom" },
              padding: "1rem 0 0 0",
              labelStyle: {
                fontSize: 12,
              },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              markGap: 3,
              itemGap: 10,
            },
          }}
          xAxis={[
            {
              data: !graphData.dates.length
                ? [`${new Date().toISOString()}`]
                : graphData.dates,
              scaleType: "point",
              valueFormatter: (value) =>
                value == null ? "" : new Date(value).toLocaleDateString(),
            },
          ]}
          yAxis={[{ data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }]}
          series={createGraphData(graphData.injuryData, graphData.general)}
        />
      </LocalizationProvider>
    </div>
  );
}
