import { Launch } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

export default function Columns({
  logType,
  log,
  colstyle,
  callDirectionIcon,
  getFormattedDate,
  getFormattedTime,
  formatted,
  fallbackString,
  originKey,
  destinationKey,
  navigateToDetail,
}) {
  const isClient = () => {
    ///so many null/undefined checks.
    if (logType === "phone") {
      return (log[originKey] &&
        log[originKey].users &&
        log[originKey].users[0]?.is_client) ||
        (log[destinationKey] &&
          log[destinationKey].users &&
          log[destinationKey].users[0]?.is_client)
        ? true
        : false;
    }
    return (log[originKey] &&
      log[originKey].users &&
      log[originKey].users[0]?.is_client) ||
      (log[destinationKey] &&
        log[destinationKey][0].users &&
        log[destinationKey][0].users[0]?.is_client)
      ? true
      : false;
  };
  const getClientId = () => {
    ///so many null/undefined checks.
    if (logType === "phone") {
      return log[originKey] &&
        log[originKey].users &&
        log[originKey].users[0].is_client
        ? log[originKey].users[0].user
        : log[destinationKey] &&
          log[destinationKey].users &&
          log[destinationKey].users[0].is_client
        ? log[destinationKey].users[0].user
        : null;
    }
    return log[originKey] &&
      log[originKey].users &&
      log[originKey].users[0].is_client
      ? log[originKey].users[0].user
      : log[destinationKey] &&
        log[destinationKey][0].users &&
        log[destinationKey][0].users[0].is_client
      ? log[destinationKey][0].users[0].user
      : null;
  };
  return (
    <div className="flex items-center" key={log.id}>
      <div className="w-[95%]">
        <div className={colstyle ? (colstyle.inner ? colstyle.inner : "") : ""}>
          <div
            className={
              colstyle ? (colstyle.column1 ? colstyle.column1 : "") : ""
            }
            id="direction"
            data-testid="direction"
          >
            {callDirectionIcon(log.voice_system_direction)}
          </div>
          <div
            className={
              colstyle ? (colstyle.column2 ? colstyle.column2 : "") : ""
            }
            id="caller_userName"
            data-testid="caller_userName"
          >
            {log[originKey] &&
            log[originKey]?.users &&
            log[originKey]?.users[0]?.user_name ? (
              <div>{log[originKey]?.users[0].user_name}</div>
            ) : (
              fallbackString
            )}
          </div>
          <div
            className={
              colstyle ? (colstyle.column3 ? colstyle.column3 : "") : ""
            }
            id="receiver_userName"
            data-testid="receiver_userName"
          >
            {log[destinationKey] &&
            log[destinationKey]?.users &&
            log[destinationKey]?.users[0]?.user_name ? (
              <div>{log[destinationKey].users[0].user_name}</div>
            ) : (
              fallbackString
            )}
          </div>
          <div
            className={
              colstyle ? (colstyle.column4 ? colstyle.column4 : "") : ""
            }
            id="date"
            data-testid="date"
          >
            <div className="date text-xs leading-6">
              {log.start_time ? getFormattedDate(log.start_time) : " "}
            </div>
            <div
              className="date text-sm leading-6"
              id="time"
              data-testid="time"
            >
              {log.start_time ? getFormattedTime(log.start_time) : " "}
            </div>
          </div>
          {logType === "phone" && (
            <>
              <div
                className={
                  colstyle ? (colstyle.column5 ? colstyle.column5 : "") : ""
                }
                id="duration"
                data-testid="duration"
              >
                {log.duration ? formatted(log.duration) : " "}
              </div>
              <div
                className={
                  colstyle ? (colstyle.column6 ? colstyle.column6 : "") : ""
                }
                id="outcome"
                data-testid="outcome"
              >
                {log.outcome ?? " "}
              </div>
            </>
          )}
          {logType === "sms" && (
            <>
              <div
                className={
                  colstyle ? (colstyle.column7 ? colstyle.column7 : "") : ""
                }
                id="duration"
                data-testid="duration"
              >
                {log.message}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="w-[5%]">
        {isClient() ? (
          <Tooltip title="Open Case Details">
            <IconButton
              onClick={() => navigateToDetail(getClientId())}
              className="hover:bg-purple-600 hover:text-white justify-start text-purple-600 ml-1"
            >
              <Launch />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
