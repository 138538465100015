import React, { useCallback } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { ExpandMore, Group, Launch } from "@mui/icons-material";
import styled from "@emotion/styled";
import Colors from "../../Colors";

export default function AccordionColumns({
  logType,
  log,
  colstyle,
  callDirectionIcon,
  getFormattedDate,
  getFormattedTime,
  formatted,
  fallbackString,
  displayNameWithFallback,
  originKey,
  destinationKey,
  navigateToDetail,
}) {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      border: "1px solid #dadde9",
    },
  }));
  const isClient = () => {
    ///so many null/undefined checks.
    if (logType === "phone") {
      return (log[originKey] &&
        log[originKey].users &&
        log[originKey].users[0]?.is_client) ||
        (log[destinationKey] &&
          log[destinationKey].users &&
          log[destinationKey].users[0]?.is_client)
        ? true
        : false;
    }
    return (log[originKey] &&
      log[originKey].users &&
      log[originKey].users[0]?.is_client) ||
      (log[destinationKey] &&
        log[destinationKey][0].users &&
        log[destinationKey][0].users[0]?.is_client)
      ? true
      : false;
  };
  const getClientId = () => {
    ///so many null/undefined checks.
    if (logType === "phone") {
      return log[originKey] &&
        log[originKey].users &&
        log[originKey].users[0].is_client
        ? log[originKey].users[0].user
        : log[destinationKey] &&
          log[destinationKey].users &&
          log[destinationKey].users[0].is_client
        ? log[destinationKey].users[0].user
        : null;
    }
    return log[originKey] &&
      log[originKey].users &&
      log[originKey].users[0].is_client
      ? log[originKey].users[0].user
      : log[destinationKey] &&
        log[destinationKey][0].users &&
        log[destinationKey][0].users[0].is_client
      ? log[destinationKey][0].users[0].user
      : null;
  };

  const renderGroup = useCallback((group) => {
    if(group.length === 1){
      return group[0].users[0].user_name;
    }
    return (
      <HtmlTooltip
        key={"ac_tool" + log.id}
        title={
          <React.Fragment>
            <div
              style={{ marginBottom: "1rem", borderBottom: "1px solid #ccc" }}
            >
              Group Message Recipients:
            </div>
            {group
              ? group.length > 0
                ? group.map((mainItem, idx) => {
                      return mainItem.users && mainItem.users.length > 0 ? mainItem.users.map((item) => {
                        return (
                          <div>
                            {item.user_name}
                            {/* Disply Asterisks if there are shared phone numbers*/}
                           {group[idx].users.length > 1 ? "*" : ""} 
                            {item?.is_client && (
                              <Tooltip title="Open Case Details">
                                <IconButton
                                  onClick={() =>
                                    navigateToDetail(
                                      item?.is_client ? item?.user : null
                                    )
                                  }
                                  className="hover:bg-purple-600 hover:text-white justify-start text-purple-600 ml-1"
                                >
                                  <Launch />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        );
                      })
                      : ""
                  })
                : ""
              : "" }
          </React.Fragment>
        }
      >
        <div>
          <Typography color="inherit">
            <div className="flex">
            {group && group.length > 1 ? (
                <div className="mr-1">
                  <Group style={{ color: Colors.green[600] }} />{" "}
                </div>
              ) : (
                ""
              )}

              {group[0] ? (
                group[0].users[0] ? (
                  <div>
                    {group[0].users[0].user_name}
                    {group[0].users.length > 1 ? "*" : ""}
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </Typography>
        </div>
      </HtmlTooltip>
    );
  }, [log.id, navigateToDetail]);
  return (
    <div className="flex items-center" key={"ac_ac_" + log.id}>
      <div className="w-[95%]">
        <Accordion
          className="relative z-10 bg-white"
          defaultExpanded={false}
          sx={{
            padding: "0px",
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            sx={{
              padding: "0px",
              ".MuiAccordionSummary-contentGutters": {
                padding: " 5px 0px",
                margin: "0px",
              },
              ".MuiAccordionSummary-expandIconWrapper": {
                position: "absolute",
                right: "5px",
              },
            }}
            expandIcon={
              <div
                className="flex items-center"
                style={{ position: "relative" }}
              >
                <ExpandMore />
              </div>
            }
            aria-controls={log && log.id ? `${log.id}-content` : ""}
            id={log && log.id ? `${log.id}-header` : ""}
          >
            <div
              className={colstyle ? (colstyle.inner ? colstyle.inner : "") : ""}
              style={{ border: "0px" }}
            >
              <div
                className={
                  colstyle ? (colstyle.column1 ? colstyle.column1 : "") : ""
                }
                id={log.id + "_direction"}
                data-testid={log.id + "_test_direction"}
              >
                {callDirectionIcon(log.voice_system_direction)}
              </div>
              <div
                className={
                  colstyle ? (colstyle.column2 ? colstyle.column2 : "") : ""
                }
                id={log.id + "_caller_userName"}
                data-testid={log.id + "_test_caller_userName"}
              >
                {/* Disply First user in Multiple User List*/}
                {log[originKey]
                  ? log[originKey]?.users && log[originKey]?.users.length > 0
                    ? log[originKey]?.users[0]?.user_name
                    : fallbackString
                  : fallbackString}
                {/* Disply Asterisks if there are shared phone numbers*/}
                {log[originKey] &&
                log[originKey].users &&
                log[originKey].users.length > 1
                  ? "*"
                  : ""}
              </div>
              <div
                className={
                  colstyle ? (colstyle.column3 ? colstyle.column3 : "") : ""
                }
                id={log.id + "_receiver_userName"}
                data-testid={log.id + "_test_receiver_userName"}
              >
                {/* Disply First user in Multiple User List*/}
                {logType === "phone"
                  ? log[destinationKey]
                    ? log[destinationKey].users
                      ? log[destinationKey]?.users.length > 0
                        ? log[destinationKey]?.users[0].user_name
                        : fallbackString
                      : fallbackString
                    : fallbackString
                  : ""}
                {/* Disply Asterisks if there are shared phone numbers*/}
                {logType === "phone" &&
                log[destinationKey] &&
                log[destinationKey].users &&
                log[destinationKey].users.length > 1
                  ? "*"
                  : ""}
                {logType === "sms"
                  ? log[destinationKey]
                    ? log[destinationKey].length > 0
                      ? renderGroup(log[destinationKey])
                      : fallbackString
                    : ""
                  : ""}
              </div>
              <div
                className={
                  colstyle ? (colstyle.column4 ? colstyle.column4 : "") : ""
                }
                id={log.id + "_date"}
                data-testid={log.id + "_test_date"}
              >
                {logType === "phone" ? (
                  <>
                    <div className="date text-xs leading-6">
                      {log.start_time ? getFormattedDate(log.start_time) : " "}
                    </div>
                    <div
                      className="date text-sm leading-6"
                      id={log.id + "_call_time"}
                      data-testid={log.id + "_test_call_time"}
                    >
                      {log.start_time ? getFormattedTime(log.start_time) : " "}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="date text-xs leading-6">
                      {log.sent_time ? getFormattedDate(log.sent_time) : " "}
                    </div>
                    <div
                      className="date text-sm leading-6"
                      id={log.id + "_sms_time"}
                      data-testid={log.id + "_test_sms_time"}
                    >
                      {log.sent_time ? getFormattedTime(log.sent_time) : " "}
                    </div>
                  </>
                )}
              </div>
              {logType === "phone" && (
                <>
                  <div
                    className={
                      colstyle ? (colstyle.column5 ? colstyle.column5 : "") : ""
                    }
                    id="duration"
                    data-testid="duration"
                  >
                    {log.duration ? formatted(log.duration) : " "}
                  </div>
                  <div
                    className={
                      colstyle ? (colstyle.column6 ? colstyle.column6 : "") : ""
                    }
                    id="outcome"
                    data-testid="outcome"
                  >
                    {log.outcome ?? " "}
                  </div>
                </>
              )}
              {logType === "sms" && (
                <>
                  <div
                    className={
                      colstyle ? (colstyle.column7 ? colstyle.column7 : "") : ""
                    }
                    id="duration"
                    data-testid="duration"
                  >
                    <Typography noWrap={true}>
                      {log.message ? log.message : ""}
                    </Typography>
                  </div>
                </>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails className="border-t border-gray-300 flex ">
            <div
              className={colstyle ? (colstyle.inner ? colstyle.inner : "") : ""}
              style={{ border: "0px", alignItems: "start" }}
            >
              <div
                id={log.id + "_detail_caller_userName"}
                data-testid={log.id + "_test_detail_caller_userName"}
                style={{
                  minWidth: "30%",
                  display:
                    log[originKey] &&
                    log[originKey].users &&
                    log[originKey].users.length > 1
                      ? "block"
                      : "none",
                }}
              >
                {log[originKey]
                  ? log[originKey].users && log[originKey].users.length > 0
                    ? displayNameWithFallback(
                        log.id,
                        log[originKey]?.users,
                        log[originKey]?.number
                      )
                    : ""
                  : fallbackString}
              </div>

              {logType === "phone" ? (
                log[destinationKey] ? (
                  log[destinationKey].users ? (
                    log[destinationKey].users.length > 0 ? (
                      <div
                        style={{
                          minWidth: "30%",
                          display:
                            log[originKey] &&
                            log[destinationKey].users &&
                            log[destinationKey].users.length > 0 &&
                            log[destinationKey].users.length > 1
                              ? "block"
                              : "none",
                        }}
                        id={log.id + "_detail_receiver_userName"}
                        data-testid={log.id + "_test_detail_receiver_userName"}
                      >
                        {displayNameWithFallback(
                          log.id,
                          log[destinationKey]?.users,
                          log[destinationKey]?.number
                        )}
                      </div>
                    ) : (
                      fallbackString
                    )
                  ) : (
                    fallbackString
                  )
                ) : (
                  fallbackString
                )
              ) : (
                ""
              )}

              {logType === "sms" ? (
                log[destinationKey] ? (
                  log[destinationKey].length > 0 ? (
                    <div
                      style={{
                        minWidth: "30%",
                        display:
                          log[originKey] &&
                          log[destinationKey].users &&
                          log[destinationKey].users.length > 0 &&
                          log[destinationKey].map((contact) =>
                            contact.users.length > 1 ? "block" : "none"
                          ),
                      }}
                      id={log.id + "_detail_receiver_userName"}
                      data-testid={log.id + "_test_detail_receiver_userName"}
                    >
                      {log[destinationKey].map((contact, idx) =>
                        displayNameWithFallback(
                          idx + "_" + contact.number,
                          contact.users,
                          contact.number
                        )
                      )}
                    </div>
                  ) : (
                    fallbackString
                  )
                ) : (
                  fallbackString
                )
              ) : (
                ""
              )}
              <div
                style={{
                  height: "100%",
                  minWidth: "30%",
                  maxWidth: "90%",
                  padding: "1rem",
                  display: logType === "sms" ? "block" : "none",
                  overflowWrap: "break-word",
                }}
                id={log.id + "_detail_sms_message"}
                data-testid={log.id + "_test_detail_sms_message"}
              >
                <p className="text-gray-400 text-center mb-4">
                  ---- Full Message Begins ----
                </p>
                {log.message ? log.message : ""}
                <p className="text-gray-400 text-center mt-4">
                  ---- Full Message Ends ----
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="w-[5%]">
        {isClient() ? (
          <Tooltip title="Open Case Details">
            <IconButton
              onClick={() => navigateToDetail(getClientId())}
              className="hover:bg-purple-600 hover:text-white justify-start text-purple-600 ml-1"
            >
              <Launch />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
