import { List, ListItem } from "@mui/material";
import React from "react";
import InjuryForm from "./InjuryForm";

export default function LatestInjurySummary({
  injury,
  casePk,
  trigger,
  setTrigger,
}) {
  return !injury ? (
    <p className="text-center">Something went wrong</p>
  ) : (
    <div>
      <h2 className="text-[1.2rem] font-bold text-center pb-1">Pain Level</h2>
      {!injury.created_by ? (
        ""
      ) : (
        <List className="text-[12px] pt-0">
          <ListItem className="justify-center p-0">
            Updated at: {new Date(injury.created_at).toLocaleString()}
          </ListItem>
          <ListItem className="justify-center p-0">
            Updated by:{" "}
            {!injury.created_by ? "Unknown" : injury.created_by.name}
          </ListItem>
        </List>
      )}
      <div>
        <InjuryForm
          injury={injury}
          casePk={casePk}
          trigger={trigger}
          setTrigger={setTrigger}
        />
      </div>
    </div>
  );
}
