import React, { useContext } from "react";
import CommLogItem from "./CommLogItem";
import { CircularProgress, List } from "@mui/material";
import { CommLogStateContext } from "../context/CommLogStateContext";

export default function CommLogList({ logList, logType }) {
  const  state  = useContext(CommLogStateContext);
  let styles = {
    outer: "text-sm py-0 px-0 w-[90%]",
    inner:
      "text-black w-full flex bg-white border-gray-100 border-y items-center py-0 px-4 ",
    column1: "w-[10%] text-left align-middle ",
    column2: "w-[19%] text-left ",
    column3: "w-[19%] text-left align-middle ",
    column4: "w-[15%] text-center align-middle ",
    column5: "w-[17%] text-center align-middle ",
    column6: "w-[19%] text-center align-middle ",
    column7: "w-[18rem] text-center align-middle truncate text-ellipsis ",
    header: "text-xs uppercase font-bold py-3 align-middle ",
  };

  return (
    !state.loading ?
    <div
      id="list-container"
      data-testid="list-container"
      className="h-[58.5vh]"
    >
      <div className={styles.outer}>
        <div className={styles.inner}>
          <div className={styles.column1 + styles.header}>Dir.</div>
          <div className={styles.column2 + styles.header + "text-left"}>
            Caller
          </div>
          <div className={styles.column3 + styles.header}>Receiver</div>
          
          {logType === "phone" &&
          <>
          <div className={styles.column4 + styles.header}>Start Date/Time</div>
          <div className={styles.column5 + styles.header}>Duration</div>
          <div className={styles.column6 + styles.header}>Outcome</div>
          </>
          }
          {logType === "sms" &&
          <>
          <div className={styles.column4 + styles.header}>Sent Time</div>
          <div className={styles.column7 + styles.header}>Message</div>
          </>
          }
        </div>
      </div>
      {!logList ? (
        <div id="loading-spinner" data-testid="loading">
          <CircularProgress color="secondary" />
        </div>
      ) : logList.length === 0 ? (
        <div className="text-center my-2 py-20" data-testid="no-items">
          No Logs to Show
        </div>
      ) : (
        <List className="max-h-[48vh] overflow-y-auto border-gray-200 border-y">
          <div className="items-list">
            {logList.map((log, idx) => (
              <CommLogItem
                key={log.id + "_" + idx}
                logType={logType}
                log={log}
                idx={idx}
                styles={styles}
              />
            ))}
          </div>
        </List>
      )}
    </div>
    :
    <CircularProgress color="secondary"/>
  );
}
