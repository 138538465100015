import React, { useContext, useEffect, useState } from "react";
import axios from "../../../../../../api/axios";
import DataContext from "../../../../../../../context/DataContext";
import LatestInjurySummary from "./LatestInjurySummary";
import { CircularProgress } from "@mui/material";

export default function InjuryDetail({ caseObj, trigger, setTrigger }) {
  const { userRoles, accessToken } = useContext(DataContext);
  const [injuryData, setInjuryData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      !userRoles.permissions.includes("casemanager.view_casereview") ||
      !caseObj
    ) {
      return;
    } else {
      setLoading(true);
      axios
        .get(`/api/case_reviews/?case=${caseObj.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const data = response.data.results;
          setLoading(false);
          setInjuryData(data.length === 0 ? [] : data[0]);
        })
        .catch((res) => {
          setInjuryData([]);
          setLoading(false);
          return res;
        });
    }
  }, [userRoles.permissions, accessToken, setInjuryData, caseObj, trigger]);

  return !injuryData || loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <div>
      <LatestInjurySummary
        injury={injuryData}
        casePk={!caseObj ? "" : caseObj.pk}
        trigger={trigger}
        setTrigger={setTrigger}
      />
    </div>
  );
}
