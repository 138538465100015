import InjuryGraph from "./InjuryGraph";
import InjuryDetail from "./InjuryDetail";
import { useState } from "react";

export default function Injuries({ caseObj }) {
  const [trigger, setTrigger] = useState(false);
  return (
    <div className="h-[60vh] w-full overflow-y-auto">
      <div className="w-full">
        <InjuryDetail
          caseObj={caseObj}
          trigger={trigger}
          setTrigger={setTrigger}
        />
      </div>
      <div className="w-full text-center mt-4">
        <InjuryGraph caseObj={caseObj} trigger={trigger} />
      </div>
    </div>
  );
}
