import React from "react";

export default function CommLogError({ errorArray }) {
  return (
    <div id="error-message" data-testid="error-message" className="m-20 text-center">
      {errorArray.map((issue, index) => {
        if (
          issue === "" ||
          issue === " " ||
          issue === null ||
          issue === undefined
        ) {
          return <div>Error. Please Try Again.</div>;
        }
        return (
          <div key={index} value={issue} className="m-20 text-center">
            {issue}
          </div>
        );
      })}
    </div>
  );
}
