import {
  Checkbox,
  FormControlLabel,
  ListItem,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export default function InjuryUpdateRow({
  injuryItem,
  genPainOptions,
  checked,
  setChecked,
  display,
  setDisplay,
  canAddData,
  initialInjuryItem,
}) {
  const [painLevel, setPainLevel] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (injuryItem) {
      getChecked(injuryItem);
      setPainLevel(injuryItem.pain_level);
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [injuryItem, setPainLevel]);

  const handleToggle = (value) => {
    getChecked(injuryItem);
    let newChecked = [...checked];
    let activeKeys = [];

    for (const item in checked) {
      activeKeys.push(checked[item].body_part);
    }

    if (activeKeys.includes(value)) {
      newChecked = newChecked.filter((item) => item.body_part !== value);
    } else {
      newChecked.push({
        body_part: value,
        pain_level: painLevel,
      });
    }
    setChecked(newChecked);
  };

  const handleLevelUpdate = (value, part, injury) => {
    setPainLevel(value);
    getChecked(injuryItem);

    const currentIndex = display.indexOf(injury);
    const newChecked = [...display];

    const currentPostIndex = checked.indexOf(injury);
    const newPostChecked = [...checked];

    if (currentIndex !== -1) {
      console.log(newPostChecked);

      newChecked.splice(currentIndex, 1, {
        body_part: part,
        pain_level: value,
      });
      newPostChecked.splice(currentPostIndex, 1, {
        body_part: part,
        pain_level: value,
      });
    } else {
      return;
    }
    setChecked(newPostChecked);
    setDisplay(newChecked);
  };

  const getChecked = (item) => {
    let activeKeys = [];
    for (const item in checked) {
      activeKeys.push(checked[item].body_part);
    }
    if (activeKeys.includes(item.body_part)) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  return (
    <ListItem className="py-0 justify-between">
      <FormControlLabel
        className="w-1/3"
        name={injuryItem.body_part}
        label={`${injuryItem.body_part}`}
        disabled={!canAddData}
        control={
          <Checkbox
            checked={isChecked}
            inputProps={{ "aria-labelledby": injuryItem.body_part }}
          />
        }
        onChange={() => handleToggle(injuryItem.body_part)}
      />
      <p className="w-1/3 text-left">
        Previous: {initialInjuryItem.pain_level}
      </p>
      <div className="flex items-center">
        <p>Current:</p>
        <Select
          size="small"
          id={`${injuryItem.body_part}-pain-level`}
          value={painLevel}
          label={`${injuryItem.body_part}-pain-level`}
          disabled={isChecked && canAddData ? false : true}
          onChange={(e) => {
            handleLevelUpdate(e.target.value, injuryItem.body_part, injuryItem);
          }}
          className="text-sm ml-2"
        >
          {genPainOptions.map((opt, idx) => (
            <MenuItem value={opt} className="text-sm" key={idx}>
              {opt}
            </MenuItem>
          ))}
        </Select>
      </div>
    </ListItem>
  );
}
