import React from "react";
import AccordionColumns from "./AccordionColumns";

export function SMSColumns({
  log,
  logType,
  columnstyle,
  callDirectionIcon,
  getFormattedDate,
  getFormattedTime,
  formatted,
  fallbackString,
  displayNameWithFallback,
  originKey,
  destinationKey,
  navigateToDetail
}) {
  return (
    <div id="SMS-Columns" data-testid="_test_SMS-Columns"  className="w-[95%]" key={'ac_div_' + log.id}>
      <AccordionColumns
        key={'ac_' + log.id}
        logType={logType}
        log={log}
        colstyle={columnstyle}
        callDirectionIcon={callDirectionIcon}
        getFormattedDate={getFormattedDate}
        getFormattedTime={getFormattedTime}
        formatted={formatted}
        fallbackString={fallbackString}
        displayNameWithFallback={displayNameWithFallback}
        originKey={originKey}
        destinationKey={destinationKey}
        navigateToDetail={navigateToDetail}
      />
    </div>
  );
}
