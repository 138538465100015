import { IconButton, ListItem, MenuItem, Select, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import AutoCompleteSearch from "../../../../../../../../global/AutoCompleteSearch/AutoCompleteSearch";
import { Close } from "@mui/icons-material";

export default function InjuryAddRow({
  genPainOptions,
  idx,
  removeFormFields,
  formValues,
  setFormValues,
  injuryItem,
}) {
  const [painLevel, setPainLevel] = useState("");
  const [bodyPart, setBodyPart] = useState("");

  const handleLevelUpdate = (value, injury) => {
    setPainLevel(value);

    const currentIndex = formValues.indexOf(injury);
    const newInjuries = [...formValues];

    if (currentIndex !== -1) {
      newInjuries.splice(currentIndex, 1, {
        body_part: bodyPart.key,
        pain_level: value,
      });
    } else {
      return;
    }
    setFormValues(newInjuries);
  };

  const handleBodyUpdate = (value, injury) => {
    setBodyPart(value);

    const currentIndex = formValues.indexOf(injury);
    const newInjuries = [...formValues];

    if (currentIndex !== -1) {
      newInjuries.splice(currentIndex, 1, {
        body_part: value.key,
        pain_level: painLevel,
      });
    } else {
      return;
    }
    setFormValues(newInjuries);
  };

  useEffect(() => {
    if (bodyPart) {
      handleBodyUpdate(bodyPart, injuryItem);
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [bodyPart]);

  return (
    <ListItem className="py-0 justify-between my-2">
      <div className="flex items-center w-1/2">
        <AutoCompleteSearch
          apiUrl={`/api/field_options/?field_name=body_part&`}
          keyVal="key"
          title="Body Part"
          setVal={setBodyPart}
          displayKey="label"
        />
      </div>
      <div className="flex items-center">
        <Select
          size="small"
          id={`${injuryItem.body_part}-pain-level`}
          value={painLevel}
          label={`${injuryItem.body_part}-pain-level`}
          onChange={(e) => {
            handleLevelUpdate(e.target.value, injuryItem);
          }}
          className="text-sm ml-6 mx-2"
        >
          {genPainOptions.map((opt, idx) => (
            <MenuItem value={opt} className="text-sm" key={idx}>
              {opt}
            </MenuItem>
          ))}
        </Select>
        <Tooltip title="Cancel">
          <IconButton
            onClick={() => {
              removeFormFields(idx);
              setBodyPart("");
              setPainLevel("");
            }}
          >
            <Close className="text-red-600" />
          </IconButton>
        </Tooltip>
      </div>
    </ListItem>
  );
}
