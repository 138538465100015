import React from "react"
import AccordionColumns from "./AccordionColumns"
import Columns from "./Columns"

export function PhoneColumns({
  log,
  logType,
  columnstyle,
  callDirectionIcon,
  getFormattedDate,
  getFormattedTime,
  formatted,
  fallbackString,
  displayNameWithFallback,
  originKey,
  destinationKey,
  navigateToDetail
}){
  return(
    <div id="Phone-Columns" data-testid="_test_Phone-Columns" className="w-full">
    {log[originKey]?.users?.length > 1 ||
        log[destinationKey]?.users?.length > 1 ? (
        <AccordionColumns
          logType={logType}
          log={log}
          colstyle={columnstyle}
          callDirectionIcon={callDirectionIcon}
          getFormattedDate={getFormattedDate}
          getFormattedTime={getFormattedTime}
          formatted={formatted}
          fallbackString={fallbackString}
          displayNameWithFallback={displayNameWithFallback}
          originKey={originKey}
          destinationKey={destinationKey}
          navigateToDetail={navigateToDetail}
        />
      ) : (
        <Columns
          logType={logType}
          log={log}
          colstyle={columnstyle}
          callDirectionIcon={callDirectionIcon}
          getFormattedDate={getFormattedDate}
          getFormattedTime={getFormattedTime}
          formatted={formatted}
          fallbackString={fallbackString}
          displayNameWithFallback={displayNameWithFallback}
          originKey={originKey}
          destinationKey={destinationKey}
          navigateToDetail={navigateToDetail}
        />
      )}
      </div>
  )
}