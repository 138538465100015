import {
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import InjuryUpdateRow from "./InjuryUpdateRow";
import ResponseModal from "../../../../../../../../global/ResponseModal";
import axios from "../../../../../../../../api/axios";
import DataContext from "../../../../../../../../../context/DataContext";
import InjuryAddRow from "./InjuryAddRow";
import { ControlPointOutlined } from "@mui/icons-material";
import FormSubmitButton from "../../../../../../../../global/FormComponents/FormSubmitButton";
import ApiRequestErrorHandler from "../../../../../../../../global/ApiRequestErrorHandler";

export default function InjuryForm({ injury, casePk, trigger, setTrigger }) {
  const { accessToken, userRoles } = useContext(DataContext);
  const genPainOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [genPain, setGenPain] = useState(
    injury.client_pain_level || injury.client_pain_level === 0
      ? injury.client_pain_level
      : null
  );
  const [checkedInjuries, setCheckedInjuries] = useState(
    injury.length ? injury.injuries : []
  );
  const [displayInjuries, setDisplayInjuries] = useState(
    injury.length ? injury.injuries : []
  );
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (injury.injuries) {
      setCheckedInjuries(injury.injuries);
      setDisplayInjuries(injury.injuries);
    } else {
      return;
    }
  }, [injury]);

  const postPainLevels = () => {
    if (genPain === null && !checkedInjuries.length && !formValues.length) {
      alert("Please add General pain value to post");
    } else {
      setLoading(true);
      let postData = {
        case: +casePk,
        client_pain_level: +genPain,
        injuries:
          formValues.length > 0
            ? checkedInjuries.concat(formValues)
            : checkedInjuries,
      };

      axios
        .post(`/api/case_reviews/`, postData, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then(() => {
          setLoading(false);
          setResponseModal(true);
          setResponseBreakdown("Pain level successfully updated");
          setIsError(false);
        })
        .catch((error) => {
          const res = error.response.data;
          const errArr = ApiRequestErrorHandler(res);
          setIsError(true);
          setResponseModal(true);
          setResponseBreakdown(errArr);
          setLoading(false);
        });
    }
  };

  const closeResModal = () => {
    if (isError) {
      setIsError(false);
      setResponseModal(false);
      setResponseBreakdown("");
    } else {
      setTrigger(!trigger);
      setIsError(false);
      setResponseModal(false);
      setResponseBreakdown("");
    }
  };

  const addFormFields = () => {
    setFormValues([...formValues, { body_part: "", pain_level: "" }]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    let newInjuryValues = [...checkedInjuries];
    newFormValues.splice(i, 1);
    newInjuryValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const canAddData = userRoles.permissions.includes(
    "casemanager.add_casereview"
  );

  return (
    <div className="border-t pt-2">
      <p className="text-[12px] text-center font-bold">General Pain Level*</p>
      <RadioGroup
        className="flex-row justify-center"
        value={genPain}
        onChange={(e) => setGenPain(e.target.value)}
      >
        {genPainOptions.map((opt) => (
          <FormControlLabel
            value={opt}
            label={opt}
            key={opt}
            disabled={!canAddData}
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 12,
                  },
                }}
              />
            }
            className="text-[10px] flex-col m-0"
          />
        ))}
      </RadioGroup>
      <List>
        {!displayInjuries || displayInjuries.length === 0 ? (
          <ListItem className="justify-center">No Data Found</ListItem>
        ) : (
          displayInjuries.map((item, idx) => (
            <InjuryUpdateRow
              injuryItem={item}
              initialInjuryItem={injury.injuries[idx]}
              key={item.body_part + Math.random()}
              genPainOptions={genPainOptions}
              checked={checkedInjuries}
              setChecked={setCheckedInjuries}
              display={displayInjuries}
              setDisplay={setDisplayInjuries}
              canAddData={canAddData}
            />
          ))
        )}
        {formValues.map((item, idx) => (
          <InjuryAddRow
            key={idx}
            idx={idx}
            genPainOptions={genPainOptions}
            removeFormFields={removeFormFields}
            injuryItem={item}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        ))}
      </List>
      <div className="flex justify-end mr-2">
        {canAddData ? (
          <Tooltip title="Add Injury Data">
            <IconButton onClick={() => addFormFields()}>
              <ControlPointOutlined className="text-green-600" />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
      {(genPain === null && !checkedInjuries.length && !formValues.length) ||
      !canAddData ? (
        ""
      ) : (
        <FormSubmitButton
          isLoading={loading}
          handleSubmit={postPainLevels}
          buttonText="Submit"
          buttonColor="green"
          buttonId="pain-levels-submit"
        />
      )}

      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </div>
  );
}
